<template>
  <section class="booking--detail-survey" v-show="isAllowed">
    <div class="left--part">
      <div class="title--text">
        {{ $t('booking.tourSect.header') }}
      </div>
      <div class="terms">
        {{ $t('footer.tos1') }}
        <router-link to="/term-of-service">{{ $t('footer.tos2') }}</router-link>
        {{ $t('footer.tos3') }}
        <router-link to="/privacy-policy">{{ $t('footer.tos4') }}</router-link>
        {{ $t('footer.tos5') }}
      </div>
    </div>
    <div class="right--part">
      <div class="form--group">
        <div class="left--col">{{ $t('general.selectDate') }}</div>
        <div class="right--col">
          <div class="dropdown--wrapper" style="cursor: pointer" @click="chooseDate">
            <input
              v-model="dateFormatted"
              :placeholder="$t('general.selectDate')"
              class="basic--input"
              readonly
            />
          </div>
        </div>
      </div>
      <div class="form--group">
        <div class="left--col">{{ $t('general.selectHour') }}</div>
        <div class="right--col">
          <div class="dropdown--wrapper" @click="chooseTime">
            <input
              v-model="time"
              :placeholder="$t('general.selectHour')"
              class="basic--input"
              readonly
            />
          </div>
        </div>
      </div>
      <div class="form--group button--group">
        <button
          :disabled="!time || !date"
          class="btn btn-primary btn-block"
          @click="showModal('modal-initiate-tour-confirmation')"
        >
          {{ $t('booking.tourSect.initBtn') }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import ButtonMixin from '@/components/application-action/buttons/mixins/buttonMixin.js';
import { mapState } from 'vuex';

export default {
  name: 'initiate-tour-section',
  mixins: [ButtonMixin],
  props: {
    allowedActions: {
      required: true,
      validator: value => {
        if (value !== null) {
          return value.constructor.name === 'Array';
        } else {
          return true;
        }
      },
    },
    type: {
      validator: value => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    applicationUuid: {
      validator: value => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    bookingInfo: {
      required: true,
    },
  },
  data() {
    return {
      actionName: 'TOUR_SCHEDULE',
      actionType: 'RENT_TRANSACTION',
    };
  },

  computed: {
    ...mapState({
      date: state => state.v2.rentTransaction.initiateTour.date,
      time: state => state.v2.rentTransaction.initiateTour.time,
    }),
    dateFormatted() {
      return this.date ? this.$date.format(this.date, 'dd MMMM yyyy') : null;
    },
  },
  methods: {
    showModal(modalName) {
      this.$modal.show(modalName);
    },
    chooseDate() {
      const to = new Date();
      const from = this.$date.parse(this.bookingInfo.start_date);
      this.$modal.show('modal--initiate-tour-1', {
        to,
        from,
      });
    },
    chooseTime() {
      this.$modal.show('modal--initiate-tour-2');
    },
  },
};
</script>

<style scoped></style>
